import * as React from "react";
import { generateButtonClassName } from "./Button";

interface Props {
  href: string;
  buttonText: string;
  description: string;
  price: number;
  order: number;
  image: React.ReactNode;
}

const formatter = new Intl.NumberFormat("nl", {
  style: "currency",
  currency: "EUR",
});

export const BuyDialogOption = React.forwardRef<HTMLAnchorElement, Props>(
  (props, ref) => (
    <div
      itemProp="itemListElement"
      itemScope
      itemType="https://schema.org/Product"
    >
      <meta itemProp="name" content={`Enclave ${props.buttonText}`} />
      <meta itemProp="position" content={String(props.order)} />
      <a
        href={props.href}
        target="_blank"
        className="block rounded-md focus:outline focus:outline-red-light/70 outline-offset-4 mb-6"
        rel="noreferrer noopener"
        tabIndex={-1}
        itemProp="url"
      >
        {props.image}
      </a>

      <a
        href={props.href}
        target="_blank"
        rel="noreferrer noopener"
        className={generateButtonClassName(false, false, "block ")}
        ref={ref}
      >
        {props.buttonText}
      </a>
      <div
        className="text-xl font-bold my-3 text-red"
        itemProp="offers"
        itemScope
        itemType="https://schema.org/Offer"
      >
        <span>{formatter.format(props.price)}</span>
        <meta itemProp="price" content={String(props.price)} />
        <meta itemProp="priceCurrency" content="EUR" />
      </div>
      <div className="mt-3 text-sm">{props.description}</div>
    </div>
  )
);
