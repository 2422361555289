import { graphql, useStaticQuery } from "gatsby";
import { random } from "lodash";
import * as React from "react";
import { generateButtonClassName } from "./Button";

interface SecretPageInfo {
  uri: string;
  secretPageInfo: { secretcode: string };
}

export const SecretPageInput: React.VFC = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState<string | undefined>();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const available: { node: SecretPageInfo }[] = useStaticQuery(graphql`
    query {
      allWpPage(filter: { secretPageInfo: { secretcode: { ne: null } } }) {
        edges {
          node {
            secretPageInfo {
              secretcode
            }
            uri
          }
        }
      }
    }
  `).allWpPage.edges;

  const handleSubmit = React.useCallback<React.FormEventHandler>(
    (e) => {
      e.preventDefault();
      const value = inputRef.current?.value;
      if (!value?.length) return;
      setIsLoading(true);
      setMessage(undefined);
      setTimeout(() => {
        const result = available.find(
          (i) => i.node.secretPageInfo.secretcode === value
        );

        if (result) {
          const url = result.node.uri;
          setIsLoading(false);
          window.location.href = url;
        } else {
          setMessage("Deze code is niet geldig");
          setIsLoading(false);
        }
      }, random(500, 4000, false));
    },
    [available]
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex gap-4 flex-wrap">
        <input
          type="password"
          autoComplete="off"
          placeholder=""
          className="p-2 rounded-md bg-white border-red border-4 focus:outline-none focus:border-red-hover"
          ref={inputRef}
        />

        <input
          type="submit"
          value={isLoading ? "Bezig met laden…" : "Code opzoeken"}
          className={generateButtonClassName(false, isLoading)}
          disabled={isLoading}
        />
      </div>
      {message && (
        <div className="font-display text-red-hover mt-1">{message}</div>
      )}
    </form>
  );
};
