/** @format */
import * as React from "react";
import { socialData } from "../constants";
import { ReactComponent as ShareIcon } from "../assets/share_icon.svg";

import {
  WhatsappShareButton,
  TwitterShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TelegramIcon,
  WhatsappIcon,
  FacebookIcon,
  TwitterIcon,
} from "react-share";

const iconProps = {
  round: true,
  size: 40,
};

export const SocialIcons: React.VFC<unknown> = (props) => {
  const [showSocial, setShowSocial] = React.useState(false);

  React.useEffect(() => {
    if (navigator?.share) setShowSocial(true);
  }, []);

  return (
    <div className="flex gap-2">
      {showSocial ? (
        <button
          type="button"
          className="bg-mid rounded-full w-10 h-10 p-1 pl-2"
          aria-label="Open share dialog"
          onClick={() => {
            navigator.share?.({
              title: document.title,
              url: window.location.href,
            });
          }}
        >
          <ShareIcon className="fill-white" />
        </button>
      ) : null}
      <FacebookShareButton url={socialData.url}>
        <FacebookIcon {...iconProps} />
      </FacebookShareButton>
      <TwitterShareButton url={socialData.url}>
        <TwitterIcon {...iconProps} />
      </TwitterShareButton>
      <WhatsappShareButton url={socialData.url}>
        <WhatsappIcon {...iconProps} />
      </WhatsappShareButton>
      <TelegramShareButton url={socialData.url}>
        <TelegramIcon {...iconProps} />
      </TelegramShareButton>
    </div>
  );
};
