/** @format */
import { graphql, useStaticQuery } from "gatsby";
import { random } from "lodash";
import * as React from "react";
import { ReactComponent as NavigateIcon } from "../assets/navigate.svg";
export interface Review {
  author: string;
  review: string;
}

const Slide: React.FC<{ review: Review }> = (props) => {
  const { review, author } = props.review;

  return (
    <figure className="md:text-center cursor-grab">
      <blockquote className="max-w-prose leading-6 sm:text-lg sm:leading-7 lg:text-xl lg:leading-8 mx-auto mb-4 2xl:text-2xl 2xl:leading-10">
        {review}
      </blockquote>
      <figcaption className="mx-auto text-sm md:text opacity-70">
        {author}
      </figcaption>
    </figure>
  );
};

const LazySlider = React.lazy(() => import("./Slider"));

const SliderNavButton = React.forwardRef<
  HTMLButtonElement,
  {
    className: string;
    label: string;
  }
>((props, ref) => (
  <button type="button" ref={ref}>
    <NavigateIcon
      className={`fill-red hover:fill-red-hover focus:fill-red-hover w-10 lg:w-16 h-auto ${props.className}`}
      alt=""
    />
  </button>
));

export const Reviews: React.VFC = (props) => {
  const [initialSlideIndex, setInitialSlideIndex] = React.useState(0);
  const [hasRendered, setHasRendered] = React.useState(false);
  const [nextElRef, setNextElRef] = React.useState<HTMLButtonElement | null>();
  const [prevElRef, setPrevElRef] = React.useState<HTMLButtonElement | null>();
  const reviews: Review[] = useStaticQuery(graphql`
    query {
      wpPage(id: { eq: "cG9zdDoxMDM=" }) {
        reviews {
          reviews {
            author
            fieldGroupName
            review
          }
        }
      }
    }
  `).wpPage.reviews.reviews;

  const placeholder = <Slide review={reviews[initialSlideIndex]} />;

  React.useLayoutEffect(() => {
    setInitialSlideIndex(random(0, reviews.length - 1));
    setHasRendered(true);
  }, []);

  return !hasRendered ? (
    placeholder
  ) : (
    <div className="flex">
      <React.Suspense fallback={placeholder}>
        <SliderNavButton
          ref={setPrevElRef}
          className="rotate-180 hidden md:block"
          label="previous review"
        />
        <LazySlider
          navigation={{
            nextEl: nextElRef,
            prevEl: prevElRef,
          }}
          initialSlide={initialSlideIndex}
        >
          {reviews.map((r) => (
            <Slide review={r} key={r.author} />
          ))}
        </LazySlider>
        <SliderNavButton ref={setNextElRef} className="" label="next review" />
      </React.Suspense>
    </div>
  );
};
