import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { BuyDialogOption } from "./BuyDialogOption";
import { graphql, useStaticQuery } from "gatsby";

export const PurchaseOptions: React.FC<{
  buttonRef?: React.Ref<HTMLAnchorElement>;
}> = (props) => {
  const data = useStaticQuery(graphql`
    query {
      wpPage(id: { eq: "cG9zdDoxNDc=" }) {
        saleInfo {
          ebookdescription
          paperbackdescription
        }
      }
    }
  `).wpPage.saleInfo;

  return (
    <div
      className="mt-10 text-mid grid grid-flow-row md:grid-cols-2 gap-12 text-center"
      itemScope
      itemType="https://schema.org/ItemList"
    >
      <BuyDialogOption
        href={process.env.GATSBY_STRIPE_PAYMENT_LINK_EPUB!}
        buttonText="Ebook (.epub)"
        description={data.ebookdescription}
        ref={props.buttonRef}
        price={10.5}
        order={1}
        image={
          <StaticImage
            src="../assets/epub_mockup.png"
            height={250}
            layout="constrained"
            loading="eager"
            itemProp="image"
            alt="het boek Enclave op een iPad"
          />
        }
      />

      <BuyDialogOption
        href={process.env.GATSBY_STRIPE_PAYMENT_LINK_NL!}
        buttonText="Paperback"
        description={data.paperbackdescription}
        price={21.5}
        order={2}
        image={
          <StaticImage
            src="../assets/cover_mockup.png"
            height={250}
            layout="constrained"
            loading="eager"
            itemProp="image"
            className=""
            alt="de paperback versie van het boek Enclave"
          />
        }
      />
    </div>
  );
};
