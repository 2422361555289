import * as React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { Review, Reviews } from "../components/Reviews";
import { Layout } from "../components/Layout";
import { Button, generateButtonClassName } from "../components/Button";
import { layout } from "../constants";
import { SocialIcons } from "../components/SocialIcons";
import { getSrc, StaticImage } from "gatsby-plugin-image";
import { isNull, omitBy } from "lodash";
import type { metaArray } from "../components/SEO";
import { RelativeTime } from "../components/RelativeTime";
import { SecretPageInput } from "../components/SecretPageInput";
import classnames from "classnames";
import { LazyBuyDialog } from "../components/LazyLoadedBuyDialog";
import { PurchaseOptions } from "../components/PurchaseOptions";

const parsePrice = (str: string) => {
  const match = str.match(/([0-9|,|.]+[0-9]+?)/);
  if (match) {
    return parseFloat(match[0].replace(",", "."));
  }
  return parsePrice;
};

function App({ data }) {
  const {
    author,
    about,
    synopsis,
    secretCode,
    coverPreview,
    newsletter,
  } = data;
  const cover = getSrc(coverPreview);
  const bookData = about.bookInfo;
  const latestPost = data.latestPost?.edges[0]?.node;
  const reviews: Review[] = data.reviews.reviews.reviews;

  const { ySpacing, gap } = layout;

  const structuredData = omitBy(
    {
      "@context": "https://schema.org",
      "@type": "Book",
      isbn: bookData.isbn,
      name: "Enclave",
      datePublished: bookData.datePublished,
      description: synopsis.content,
      image: cover,
      inLanguage: "nl",
      genre: bookData.genre,
      numberOfPages: bookData.pages,
      offers: {
        "@type": "Offer",
        // "availability": "https://schema.org/InStock",
        price: parsePrice(bookData.price),
        priceCurrency: "EUR",
      },
      author: {
        "@type": "Person",
        name: bookData.author,
        description: author.content,
        // url: "https://twitter.com/johnny_reilly",
      },
      comment: reviews.map((r) => ({
        "@type": "Comment",
        author: {
          "@type": "Person",
          name: r.author,
        },
        text: r.review,
      })),
    },
    isNull
  );

  const extraMeta: metaArray = [
    {
      name: "book:isbn",
      content: bookData.isbn,
    },
  ];

  return (
    <Layout
      headerVariation="normal"
      structuredData={structuredData}
      description={bookData.googlesummary}
      ogType="book"
      extraMeta={extraMeta}
    >
      {/* About the book */}
      <section className={ySpacing}>
        <div className="prose-lg mb-3 text-center sm:text-left  2xl:prose-xl">
          <h2>{about.title}</h2>
        </div>

        <div className={`flex flex-col lg:flex-row ${gap}`}>
          <div className="flex-auto pr-10 prose prose-lg 2xl:prose-xl">
            {parse(about.content)}
          </div>

          <div className="flex-shrink-0 md:w-1/3 prose prose-sm 2xl:prose-base">
            <table>
              <tbody>
                <tr>
                  <th>Titel:</th>
                  <td>{bookData.title}</td>
                </tr>
                <tr>
                  <th>Auteur:</th>
                  <td>{bookData.author}</td>
                </tr>
                <tr>
                  <th>Categorie:</th>
                  <td>{bookData.genre}</td>
                </tr>
                {bookData.pages ? (
                  <tr>
                    <th>Aantal Pagina's:</th>
                    <td>{bookData.pages}</td>
                  </tr>
                ) : null}
                <tr>
                  <th>Formaat:</th>
                  <td>{bookData.format}</td>
                </tr>
                <tr>
                  <th>Verschijningsdatum:</th>
                  <td>{bookData.datepublished}</td>
                </tr>
                <tr>
                  <th className="small-caps">ISBN:</th>
                  <td>{bookData.isbn}</td>
                </tr>
                <tr>
                  <th>Prijs:</th>
                  <td>{bookData.price}</td>
                </tr>
                <tr>
                  <th>Taal:</th>
                  <td>Nederlands</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      {/* preview chapter */}
      <section className={ySpacing}>
        <div className={`flex flex-col sm:flex-row ${gap}`}>
          <div className="flex-auto prose prose-lg 2xl:prose-xl">
            <h2 className="text-center sm:text-left mb-3">
              {parse(newsletter.title)}
            </h2>

            <div className="mb-4">{parse(newsletter.content)}</div>

            <form
              action="https://tinyletter.com/enclaveboek"
              method="post"
              target="popupwindow"
              onSubmit={() => {
                window.open(
                  "https://tinyletter.com/enclaveboek",
                  "popupwindow",
                  "scrollbars=yes,width=800,height=600"
                );
                return true;
              }}
            >
              <label htmlFor="tlemail">Email adres:</label>

              <div className="flex gap-4 max-w-prose flex-wrap">
                <input
                  type="text"
                  name="email"
                  id="tlemail"
                  placeholder="lado.vantaggio@voorbeeld.slt"
                  className="p-2 rounded-md bg-white border-red border-4 focus:outline-none focus:border-red-hover"
                />
                <input type="hidden" value="1" name="embed" />
                <input
                  type="submit"
                  value="Inschrijven"
                  className={generateButtonClassName(false)}
                />
              </div>
            </form>
          </div>

          <div className="flex-shrink-0 md:w-1/3 prose prose-sm 2xl:prose-base">
            <h2>Deel deze pagina</h2>
            <SocialIcons />
            <p>
              of bezoek ons op{" "}
              <a
                href="https://instagram.com/enclave.boek"
                target="_blank"
                rel="noreferrer me"
              >
                Instagram
              </a>{" "}
              en{" "}
              <a
                href="https://facebook.com/enclave.boek"
                target="_blank"
                rel="noreferrer me"
              >
                Facebook
              </a>
              .
            </p>
          </div>
        </div>
      </section>

      <section className={classnames("md:hidden text-center", ySpacing)}>
        <LazyBuyDialog size="large">Bestel het boek</LazyBuyDialog>
      </section>

      {/* Reviews */}
      <section className={ySpacing}>
        <div className="prose prose-lg mb-6 text-center sm:text-left 2xl:prose-xl">
          <h2>{data.reviews.title}</h2>
        </div>

        <Reviews />
      </section>

      {latestPost && (
        <section className={ySpacing}>
          <div className="prose prose-lg mb-62xl:prose-xl">
            <h2 className="text-center sm:text-left">Laatste update</h2>

            <article itemScope itemType="http://schema.org/BlogPosting">
              <meta itemProp="author" content="Peter Hamersma" />
              <h3 itemProp="headline" className="mb-0 leading-tight">
                <Link to={latestPost.uri} itemProp="url">
                  <span itemProp="headline">{parse(latestPost.title)}</span>
                </Link>
              </h3>

              <RelativeTime
                itemProp="datePublished"
                date={new Date(latestPost.date)}
                className="text-sm text-mid"
              >
                {latestPost.date}
              </RelativeTime>

              <div itemProp="description">{parse(latestPost.excerpt)}</div>
            </article>

            <Button to="/updates" size="small" itemProp="url">
              Lees meer updates…
            </Button>
          </div>
        </section>
      )}

      {/* Purchase */}
      <section className={ySpacing}>
        <div className="prose prose-lg 2xl:prose-xl mb-3 md:mb-5 text-center sm:text-left ">
          <h2>Beschikbaar als eBook en Paperback</h2>
          <PurchaseOptions />
        </div>
      </section>

      {/* About the author */}
      <section className={ySpacing}>
        <div className="prose prose-lg 2xl:prose-xl mb-3 md:mb-5 text-center sm:text-left ">
          <h2>{author.title}</h2>
        </div>

        <div className={`flex flex-col md:flex-row ${gap}`}>
          <div className="flex-shrink-0 md:w-1/5 text-center sm:text-left ">
            <StaticImage
              src="../assets/peter_hamersma.jpg"
              alt="een foto van Peter Hamersma"
              className="w-2/5 max-w-sm mx-auto md:w-full"
              imgClassName="rounded-full"
              aspectRatio={1}
            />
          </div>

          <div className="flex-auto">
            <div className="prose prose-lg max-w-prose 2xl:prose-xl">
              {parse(author.content)}
            </div>
          </div>
        </div>
      </section>

      {secretCode && (
        <section className={ySpacing}>
          <div className="prose prose-lg 2xl:prose-xl mb-3 md:mb-5 ">
            <h2 className="text-center sm:text-left ">{secretCode.title}</h2>
            <div className="prose prose-lg max-w-prose 2xl:prose-xl">
              {parse(secretCode.content)}
              <SecretPageInput />
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
}

export default App;

export const pageQuery = graphql`
  query FrontPageQuery {
    synopsis: wpPage(id: { eq: "cG9zdDo4Nw==" }) {
      content
    }
    author: wpPage(id: { eq: "cG9zdDo0OQ==" }) {
      title
      content
    }
    secretCode: wpPage(id: { eq: "cG9zdDoxNjU=" }) {
      title
      content
    }
    latestPost: allWpPost(sort: { order: DESC, fields: date }, limit: 1) {
      edges {
        node {
          uri
          excerpt
          slug
          title
          date
        }
      }
    }
    about: wpPage(id: { eq: "cG9zdDo1Mg==" }) {
      title
      content
      bookInfo {
        googlesummary
        author
        datepublished
        fieldGroupName
        format
        genre
        isbn
        pages
        title
        price
      }
    }
    newsletter: wpPage(id: { eq: "cG9zdDo3Nw==" }) {
      title
      content
    }
    reviews: wpPage(id: { eq: "cG9zdDoxMDM=" }) {
      title
      reviews {
        reviews {
          author
          review
        }
      }
    }
    coverPreview: file(relativePath: { eq: "cover_mockup.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, formats: JPG)
      }
    }
  }
`;
